html,
body {
  height: 100%;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  color: #333;
  margin: 0;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
a:visited {
  color: inherit;
  text-decoration: none;
}

html,
body,
button,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
    sans-serif;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

h1,
h2,
h3,
p,
span,
strong {
  line-height: 1.5;
}

button {
  background: transparent;
  position: relative;
  transition: border-color 150ms ease;
  cursor: pointer;
}

.focusable {
  position: relative;
  transition: border-color 150ms ease;
}

button::before {
  content: '';
  display: inline-block;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  border-style: solid;
  border-color: transparent;
  border-radius: inherit;
  top: -3px;
  left: -3px;
  bottom: -3px;
  right: -3px;
  opacity: 0;
  transition: border-color 150ms ease, opacity 150ms ease;
}

[aria-modal] {
  outline: 0;
  position: relative;
}

::placeholder {
  font-weight: 300;
  font-size: 14px;
}

table {
  border-spacing: 0;
  overflow-x: auto;
  text-align: left;
  width: 100%;
}

thead > tr {
  font-weight: 600;
}

td,
th {
  border-bottom: 0.1rem solid #e1e1e1;
  padding: 1rem 1.2rem;
}

td:first-child,
th:first-child {
  padding-left: 0.6rem;
}

table.centered td,
table.centered th {
  text-align: center;
}

table.centered td:first-child,
table.centered th:first-child {
  text-align: left;
}

td:not(:first-child),
th:not(:first-child) {
  text-align: right;
}

tr:last-child td {
  border: 0;
}
